import { HttpHeaders } from '@angular/common/http';

export const environment:any = {
  production: false,
  downTime: false,
  appInSigntsLog: false,
  baseUrl:'https://testapi.dnv.com/ba-ngps-certchecker-dev/',
  apimHeader:{
    'Ocp-Apim-Subscription-Key': '476aaf38a9554eeca71b01c1511161a4'
  },
  recaptchaV2: {
    siteKey: '6LcJAWwpAAAAAL2nY_qgIKD8jhvhPOwImolLS22E',
  },
  recaptchaV3: {
    siteKey: '6Lf34GspAAAAABHlFCbDkx2Tg7JgVxtuT1LbRj3J'
  }
};
